@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-custom1
}

input:focus {
  @apply outline-none;
}

textarea:focus {
  @apply outline-none;
}

button:focus {
  @apply outline-none;
}

button {
  @apply outline-none;
}

button, button:hover, li:hover, a:hover , li , a , *:hover, *
{
  -webkit-touch-callout:none;
  -webkit-text-size-adjust:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

/**:not(input):not(textarea),
::after,
::before {
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
}*/

html, body {
  -webkit-overflow-scrolling: touch;
  overflow-y:auto;
  overflow-x:hidden;
  -webkit-appearance: none;
}

textarea:not([resize="true"]) {
  resize: none !important;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
